import Card from './components/Card'
import CAction from '@/components/CAction'
import CNotificationsList from "@/components/CNotificationsList.vue";

export default {
  name: 'Notifications',
  components: { CNotificationsList, Card, CAction },
  inject: ['toast'],
  data() {
    return {
      mountedComponent: false,
      items: {},
      loading: false,
      key: 1,
      per_page: 5,
      projects: {
        per_page: 5
      },
      tasks: {
        per_page: 5
      },
      contracts: {
        per_page: 5
      },
      invites: {
        per_page: 5
      },
      system: {
        per_page: 5
      },
    }
  },
  mounted() {
    this.mountedComponent = true
  },
  created() {
    this.fetchData()
  },
  methods: {
    onDelete(payload) {
      const filteredNotifications = this.items[payload.type].notifications.data.filter(item => item.id !== payload.id)
      this.items[payload.type].notifications.data = filteredNotifications
    },
    onLoad(type) {
      this.loading = true
      this[type].per_page = this[type].per_page + this.per_page
      this.$http.topNav.getNotificationsByType({
        params: {
          type,
          per_page: this[type].per_page,
          page: this[type].page
        }
      })
        .then(res => {
          this.items[type] = res.data.data
        })
        .finally(() => this.loading = false)
    },
    fetchData() {
      this.loading = true
      this.$http.topNav
        .getNotifications({
          params: {
            per_page: this.per_page,
          },
        })
        .then((response) => (this.items = response.data.data))
        .finally(() => (this.loading = false))
    },
  },
}
