<template>
  <CCard class="notificationCard">
    <CCardBody>
      <CRow v-if="header">
        <CCol>
          <h1>{{ header }}</h1>
        </CCol>
      </CRow>
      <CRow :xs="{ cols: 1 }" class="mt-2 mx-0">
        <Header v-model:show="show" :type-name="typeName" :unread-count="item.unread_count" :show-arrow="!isEmpty" />
        <Empty v-if="isEmpty" />
        <Body
          v-else
          :show="show"
          :total="item.notifications.meta.total"
          :notifications="item.notifications.data"
          :type-name="typeName"
          @load="$emit('load', typeName)"
          @deleteItem="onDelete"
        />
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import Header from './Header'
import Body from './Body'
import Empty from './Empty'

export default {
  name: 'Cart',
  components: { Header, Body, Empty },
  props: {
    item: { type: Object, default: {} },
    header: { type: String, default: '' },
    typeName: { type: String, required: true }
  },
  computed: {
    isEmpty() {
      return !this.item.notifications.data.length
    }
  },
  methods: {
    onDelete(id) {
      this.item.notifications.data = this.item.notifications.data.filter(x => x.id !== id)
    }
  },
  data() {
    return {
      show: true,
    }
  }
}
</script>

<style scoped>
.notificationCard h1 {
  font-size: 28px;
  font-weight: 700;
  line-height: 37.24px;
}
</style>
