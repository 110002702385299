<template>
  <div class="n-list">

    <div class="n-list__bar" @click="toggleItemsDisplay">
      <div class="n-list__title">
        <CIcon :name="icons[type]" />
        <p>{{ type }}</p>
        <div v-if="data.unread_count" class="n-list__counter">
          <span>{{ data.unread_count }}</span>
        </div>
      </div>
      <div v-show="listLength">
        <CIcon v-if="isOpened" name="cilArrowDown" />
        <CIcon v-else name="cilArrowUp" />
      </div>
    </div>

    <div v-if="listLength && isOpened" class="n-list__items">
      <CNotification
        v-for="notification in data.notifications.data"
        style="margin-bottom: 15px"
        :key="notification.id"
        :data="notification"
        :type-name="type"
        @onDelete="$emit('deleteItem', $event)" />
    </div>

    <button
      v-if="loadingAvailable && isOpened"
      :disabled="loading"
      class="n-list__load"
      @click="$emit('load', type)">
        Show more
    </button>

    <h3 v-if="!listLength" class="n-list__placeholder">
      No new notifications yet
    </h3>

  </div>
</template>

<script>
import CNotification from "@/components/CNotification.vue";

export default {
  name: "CNotificationsList",
  components: { CNotification },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    type: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isOpened: true,
      icons: {
        projects: 'cilProjects',
        tasks: 'cilTasks',
        contracts: 'cilDocument',
        system: 'cilSettings',
        invites: 'cilUserFollow',
      },
      limitReached: false
    }
  },
  methods: {
    toggleItemsDisplay() {
      this.isOpened = !this.isOpened
    }
  },
  computed: {
    listLength() {
      return this.data.notifications.data.length
    },
    loadingAvailable() {
      return this.listLength && this.data.notifications.meta.total > this.listLength && !this.limitReached
    }
  },
  watch: {
    listLength: function (val) {
      if (val >= this.data.notifications.meta.total) {
        this.limitReached = true
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.n-list {
  padding: 30px;
  background: white;
  margin-bottom: 5px;
  border-radius: 5px;

  &__load {
    color: rgba(44,57,74,0.95);
    border: 1px solid rgba(44,57,74,0.95);
    background: white;
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    border-radius: 0.2rem;
    display: block;
    margin: 0 auto;

    &:hover {
      background: #697588;
      color: white;
    }
  }

  &__counter {
    margin-left: 10px;
    height: 20px;
    width: 20px;
    background: #0068AD;
    border-radius: 200px;
    display: flex;
    align-items: center;
    justify-content: center;

    & span {
      color: white;
      font-size: 10px;
      font-weight: 400;
      transform: translateX(-0.5px);
    }
  }

  &__bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  &__title {
    display: flex;
    align-items: center;

    & svg {
      height: 18px !important;
      width: 18px !important;
    }

    & p {
      margin-left: 10px;
      font-size: 22px;
      font-weight: 600;
      text-transform: capitalize;
      margin-bottom: 0;
    }
  }

  &__items {
    margin-top: 25px;
  }

  &__placeholder {
    color: #b1b7c1;
    font-weight: 600;
    font-size: 22px;
    text-align: center;
  }
}
</style>
