<template>
  <CCol xs="12" class="emptyNotifications text-center">
    <h2>No new notifications yet</h2>
  </CCol>
</template>

<script>
export default {
  name: 'Empty',
}
</script>

<style scoped>
.emptyNotifications h2 {
  color: #b1b7c1;
  font-weight: 600;
  font-size: 22px;
  line-height: 29.26px;
}
</style>
