<template>
    <div v-loading="loading" class="notification">
        <div class="notification__label">
            <div v-if="!downloadable" class="notification__icon" :class="iconClass">
                <CIcon :name="iconName"/>
            </div>
        </div>
        <div class="notification__body">
            <div class="notification__content" :class="{ 'notification__content-new': !data.viewed }"
                 v-html="data.message">

            </div>
            <span class="notification__date">
        {{ date }}
      </span>
        </div>
        <div class="notification__close">
            <CIcon name="cilX" @click="handleDelete"/>
        </div>
        <ConfirmDialog ref="confirmDialog"/>
    </div>
</template>

<script>
import ConfirmDialog from "@/components/Modals/ConfirmDialog.vue";
import moment from "moment/moment";

const ICONS = {
    user_added: 'cilUserFollow',
    deletedFrom: 'cilUserUnfollow',
    completed: 'cilCheckAlt',
    rejected: 'cilX',
    comment: 'cilCommentBubble',
    pending_signature: 'cilPenNib',
    bell: 'cilBell',
    approved: 'cilFileCheck',
    uploaded: 'cilUploadPlace',
    default: 'cilCheckAlt',

    signed: 'cilPenNib',
    deleted: 'cilUserUnfollow',
    changed: 'cilArrowRight',
    downloaded: '',
    user_removed: 'cilUserUnfollow',
}

export default {
    name: "CNotification",
    components: {ConfirmDialog},
    inject: ['toast'],
    data() {
        return {
            icons: ICONS,
            loading: false
        }
    },
    props: ['data', 'typeName'],
    computed: {
        date() {
            return moment(this.data.created_at).local().format('MM.DD.YYYY HH:mm:ss')
        },
        downloadable() {
            return this.data.additional_data &&
                this.data.additional_data.task_preview_archive &&
                this.data.additional_data.task_preview_archive.status === 'done'
        },
        iconClass() {
            let obj = {}
            let className = `notification__icon-${this.data.icon}`
            obj[className] = true
            return obj
        },
        iconName() {
            return this.data.icon
        },
    },
    methods: {
        handleDelete() {
            this.$refs.confirmDialog
                .confirm({
                    size: 'sm',
                    reverse: true,
                    text: 'Are you sure you want to delete notification?',
                    info: `" ${this.data.message} "`,
                    cancelText: 'Cancel',
                    confirmText: 'Delete',
                })
                .then((res) => {
                    if (res) this.deleteNotification()
                })
        },
        deleteNotification() {
            this.loading = true
            this.$http.topNav
                .deleteNotification(this.data.id)
                .then((res) => {
                    this.toast('success', res.data.message)
                    this.$emit('onDelete', {type: this.typeName, id: this.data.id})
                })
                .catch(res => this.toast('success', res.data.message))
                .finally(() => this.loading = false)
        },
    }
};
</script>

<style lang="scss">
.notification {
    display: flex;

    &__label {

    }

    &__icon {
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        border: 1px solid #c0c0c042;

        &-default {
            background: rgba(46, 184, 92, 0.2);
            color: rgba(46, 184, 92, 1);
        }

        &-downloadable {
            cursor: pointer;
            background: rgb(200, 201, 203, 0.2);
            color: black;

            &:hover {
                background: rgb(200, 201, 203, 0.3);
            }
        }

        &-cilUserFollow {
            background: rgba(46, 184, 92, 0.2);
            color: rgba(46, 184, 92, 1);
        }

        &-cilCheckAlt {
            background: rgba(46, 184, 92, 0.2);
            color: rgba(46, 184, 92, 1);
        }

        &-cilUploadPlace {
            background: rgba(0, 93, 157, 0.2);
            color: rgba(0, 93, 157, 1);
        }

        &-cilPenNib {
            background: rgba(0, 93, 157, 0.2);
            color: rgba(0, 93, 157, 1);
        }

        &-cilUserUnfollow {
            background: rgba(229, 83, 83, 0.2);
            color: rgba(229, 83, 83, 1);
        }

        &-cilFileCheck {
            background: rgba(48, 60, 84, 0.2);
            color: rgba(48, 60, 84, 1);
        }

        &-cilCommentBubble {
            background: rgba(0, 93, 157, 0.2);
            color: rgba(0, 93, 157, 1);
        }

        &-cilCloudDownload {

        }

        &-cilBell {
            background: rgba(229, 83, 83, 0.2);
            color: rgba(229, 83, 83, 1);
        }

        &-cilX {
            background: rgba(229, 83, 83, 0.2);
            color: rgba(229, 83, 83, 1);
        }

        & svg {
            color: inherit;
        }
    }

    &__body {
        padding: 0 12px;
        width: 100%;
    }

    &__content {
        font-family: 'Segoe UI';
        font-style: normal;
        font-size: 14px;
        line-height: 19px;
        color: #303C54;

        &-new {
            font-weight: 700;
        }
    }

    &__date {
        font-size: 10px;
        color: #B1B7C1;
    }

    &__close {
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
}
</style>
