<template>
  <CRow :xs="{ cols: 1 }">
    <CCol :class="{ pointer: showArrow }" @click="toggleShow">
      <CRow :xs="{ cols: 2 }" class="justify-content-between py-3 px-2">
        <CCol xs="10" sm="11" class="headTitle">
          <h2 class="pe-3">
            <CIcon :name="icons[typeName] || ''" />
            {{ typeName }}
            <div class="headTitle__badge" v-if="unreadCount">
              <span>{{ unreadCount }}</span>
            </div>
<!--            <CBadge-->
<!--              color="primary"-->
<!--              size="sm"-->
<!--              position="top-end"-->
<!--              shape="rounded-pill"-->
<!--              class="unreadBadge"-->
<!--            >-->
<!--              {{ unreadCount }}-->
<!--            </CBadge>-->
          </h2>
        </CCol>
        <CCol xs="2" sm="1" class="text-end">
          <button class="category-collapse" v-show="showArrow">
            <CIcon v-if="show" name="cilArrowDown" />
            <CIcon v-else name="cilArrowUp" />
          </button>
        </CCol>
      </CRow>
    </CCol>
  </CRow>

</template>

<script>
export default {
  name: 'Header',
  props: {
    show: { type: Boolean, default: false },
    showArrow: { type: Boolean, default: true },
    typeName: { type: String, default: '' },
    unreadCount: { type: Number, default: 0 },
  },
  emits: ['update:show'],
  data() {
    return {
      icons: {
        projects: 'cilProjects',
        tasks: 'cilTasks',
        contracts: 'cilDocument',
        system: 'cilSettings',
        invites: 'cilUserFollow',
      },
    }
  },
  methods: {
    toggleShow() {
      this.$emit('update:show', !this.show)
    },
  },
}
</script>

<style scoped>
.category-collapse {
  padding: 0;
  margin: 0;
  background: none;
  outline: none;
  border: none;
}
.headTitle h2 {
  display: inline-flex;
  align-items: center;
  font-size: 22px;
  font-weight: 600;
  line-height: 29.26px;
  text-transform: capitalize;
}
.headTitle__badge {
  margin-left: 10px;
  height: 20px;
  width: 20px;
  background: #0068AD;
  border-radius: 200px;
  color: white;
  font-size: 10px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
}


.headTitle__badge span {
  transform: translateY(-0.5px);
}

.headTitle svg {
  height: 18px !important;
  width: 18px !important;
  margin-right: 5px;
}

.headTitle .unreadBadge {
  font-size: 0.75rem;
  background-color: #005d9d !important;
}
</style>
