<template>
  <div class="notifications">
    <teleport v-if="mountedComponent" to="#header-nav">
      <CBreadcrumbItem active>Notifications</CBreadcrumbItem>
    </teleport>

    <div class="notifications__header">
      <div class="notifications__left-side">
        <h1 class="notifications__heading">Notifications</h1>
      </div>

      <div class="notifications__right-side">
        <CAction ignore-active-project-id />
      </div>
    </div>

<!--    <Card-->
<!--      v-for="(item, key) in items"-->
<!--      :key="key"-->
<!--      :type-name="key"-->
<!--      @load="onLoad"-->
<!--      :item="item"-->
<!--      class="my-2"-->
<!--    />-->

      <CNotificationsList
        v-for="(data, type) in items"
        :key="type"
        :loading="loading"
        :data="data"
        :type="type"
        @load="onLoad"
        @deleteItem="onDelete"/>


  </div>
</template>

<script src="./script.js"></script>

<style lang="scss" scoped>
.notifications {
  padding-bottom: 20px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &__heading {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.2px;
    color: #1c262f;
  }
}
</style>
